
// DASHBOARD - TC-17960
.dash-cta-deck {
    display:none;
}
// .dashboard-content .card.dash-card-favs {
//     margin-top: 0rem;
// }


@media (min-width: 992px) {
.dashboard-content>section .container .dash-card-count-2 .dashboard-feed:first-child {
        margin-top: 0;
    }
}
@media (min-width: 576px) {
    .dashboard-content .dashboard-header>.container {
        padding-bottom: 2.2rem;
    }
}